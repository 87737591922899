import request from "@/utils/request";

/**
 * 商品订单管理
 * */
export function list(data) {
    return request({
        url: '/admin/order/search',
        method: 'post',
        data
    })
}

/**
 * 商品订单详情
 * */
export function goodsOrderDetails(id) {
    return request({
        url: '/admin/order/detail',
        method: 'post',
        data: {
            id
        }
    })
}

/**
 * 完成订单
 * */
export function goodsOrderFihish(orderId) {
    return request({
        url: '/admin/order/doOrderFihish',
        method: 'post',
        data: {
            orderId
        }
    })
}

/**
 * 租车订单
 * */
export function carRentalOrderList(data) {
    return request({
        url: '/admin/order/rent/search',
        method: 'post',
        data
    })
}

/**
 * 租车订单
 * */
export function carRentalOrderDetails(id) {
    return request({
        url: '/admin/order/rent/detail',
        method: 'post',
        data: {id}
    })
}

/**
 * 换车
 * */
export function transfer(data) {
    return request({
        url: '/admin/order/doChangeOrderCar',
        method: 'post',
        data
    })
}

/**
 * 退车
 * */
export function returnVehicle(orderId) {
    return request({
        url: '/admin/order/doOrderReturnCar',
        method: 'post',
        data: {
            orderId
        }
    })
}


/**
 * 确认退款
 * */
export function refund(id) {
    return request({
        url: '/admin/order/rent/doRefund',
        method: 'post',
        data: {id}
    })
}

/**
 * 商品订单导出
 * */
export function exportOrder() {
    return request({
        url: '/admin/order/downToExcel',
        method: 'post',
        responseType: 'blob',
        data: {}
    })
}

/**
 * 租车订单导出
 * */
export function exportRentOrder() {
    return request({
        url: '/admin/order/rent/downToExcel',
        method: 'post',
        responseType: 'blob',
        data: {}
    })
}







