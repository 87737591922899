export const formattingDate = (date) => {
    console.log(!date)
    if (!date) {
        return
    }
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const formatMonth = month < 10 ? "0" + month : month;
    const day = date.getDate();
    const formatDay = day < 10 ? "0" + day : day;

    return year + "-" + formatMonth + "-" + formatDay
}

/**
 * type:下载文件类型（xlsx 表格文件） 类型名等文件后缀名字
 * data: 二进制流数据
 * name: 文件名字
 * */
export const blobDownloadFile = (type, data, name) => {
    const fileType = {
        "xlsx": "application/vnd.ms-excel;charset=utf-8"
    }
    let blob = new Blob([data], {
        type: fileType[type]
    });
    console.log("----------------")
    console.log(fileType[type])
    let downloadElement = document.createElement("a");
    let href = window.URL.createObjectURL(blob); //创建下载的链接
    downloadElement.style.display = "none";
    downloadElement.href = href;
    downloadElement.setAttribute('download', name + "." + type)
    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载
    document.body.removeChild(downloadElement); //下载完成移除元素
    window.URL.revokeObjectURL(href); //释放掉blob对象
}