import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-cde31798"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "my-header"
};
const _hoisted_2 = ["id"];
import { ref, watchEffect } from "vue";
import { addMaintenanceRecord, maintenanceRecord } from "@/api/vehicle";

import { ElMessageBox, ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message-box/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  __name: 'maintain-record',
  props: {
    show: Boolean,
    carId: String
  },
  emits: ["alertCloseCallback"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const dialogTableVisible = ref(false);
    const gridData = ref([]);
    const isStatus = ref(1);
    const getData = () => {
      maintenanceRecord(props.carId).then(res => {
        console.log(res);
        gridData.value = res.data.carMaintenanceDetailInfoList;
        isStatus.value = res.data.addCarMaintenanceStatus;
      });
    };
    watchEffect(() => {
      dialogTableVisible.value = props.show;
      if (props.carId !== "" && props.show) {
        getData();
      }
    });
    const emit = __emit;
    const closeCallback = () => {
      emit('alertCloseCallback');
    };
    const open = () => {
      ElMessageBox.prompt('请输入维修描述', '维修描述', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({
        value
      }) => {
        addMaintenanceRecord(props.carId, value).then(res => {
          console.log(res);
          getData();
        });
        ElMessage({
          type: 'success',
          message: `Your email is:${value}`
        });
      }).catch(() => {
        ElMessage({
          type: 'info',
          message: '取消添加维修记录'
        });
      });
    };
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_CircleCloseFilled = _resolveComponent("CircleCloseFilled");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: dialogTableVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => dialogTableVisible.value = $event),
        onClose: closeCallback,
        "show-close": false,
        width: "70%"
      }, {
        header: _withCtx(({
          close,
          titleId,
          titleClass
        }) => [_createElementVNode("div", _hoisted_1, [_createElementVNode("h4", {
          id: titleId,
          class: _normalizeClass(titleClass)
        }, "维修记录", 10, _hoisted_2), _createElementVNode("div", null, [isStatus.value === 0 ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          onClick: open,
          type: "warning"
        }, {
          default: _withCtx(() => [_createTextVNode("添加记录")]),
          _: 1
        })) : _createCommentVNode("", true), _createVNode(_component_el_button, {
          type: "danger",
          onClick: close
        }, {
          default: _withCtx(() => [_createVNode(_component_el_icon, {
            class: "el-icon--left"
          }, {
            default: _withCtx(() => [_createVNode(_component_CircleCloseFilled)]),
            _: 1
          }), _createTextVNode(" 关闭 ")]),
          _: 2
        }, 1032, ["onClick"])])])]),
        default: _withCtx(() => [_createVNode(_component_el_table, {
          data: gridData.value
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            property: "id",
            label: "维修ID"
          }), _createVNode(_component_el_table_column, {
            property: "vdesc",
            label: "描述"
          }), _createVNode(_component_el_table_column, {
            property: "optUserName",
            label: "操作人"
          }), _createVNode(_component_el_table_column, {
            property: "strCreateTime",
            label: "操作时间"
          })]),
          _: 1
        }, 8, ["data"])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
};