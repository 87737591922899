import request from "@/utils/request";
import {BASE_URL} from "@/utils/config";

/**
 * 车型列表
 * */
export function typeList(data) {
    return request({
        url: '/admin/cartype/search',
        method: 'post',
        data
    })
}


/**
 * 添加车型
 * */
export function addVehicleType(data) {
    return request({
        url: '/admin/cartype/doAdd',
        method: 'post',
        data
    })
}

//更新车型
export function updateVehicleType(data) {
    return request({
        url: '/admin/cartype/doUpdate',
        method: 'post',
        data
    })
}

/**
 * 车型详情
 * */
export function vehicleTypeDetail(id) {
    return request({
        url: '/admin/cartype/detail',
        method: 'post',
        data: {id}
    })
}

/**
 * 车辆列表
 * */
export function vehicleList(data) {
    return request({
        url: '/admin/car/search',
        method: 'post',
        data
    })
}

//车型列表
export function cartype() {
    return request({
        url: '/admin/cartype/list',
        method: 'post',
    })
}

//添加车辆
export function addVehicle(data) {
    return request({
        url: '/admin/car/doAdd',
        method: 'post',
        data
    })
}

//获取车辆详情
export function vehicleDetail(id) {
    return request({
        url: '/admin/car/detail',
        method: 'post',
        data: {id}
    })
}

//更新车辆信息
export function updateVehicle(data) {
    return request({
        url: '/admin/car/doUpdate',
        method: 'post',
        data
    })
}

export function uploadingVehicle() {
    return BASE_URL + "/admin/car/doBatchImport"
}


export function lockUnlockCar(id, lockStatus) {
    return request({
        url: '/admin/car/doCarLock',
        method: 'post',
        data: {
            id,
            lockStatus
        }
    })
}


/**
 * 车辆表导出
 * */
export function exportCarOrder() {
    return request({
        url: '/admin/car/downToExcel',
        method: 'post',
        responseType: 'blob',
        data: {}
    })
}

/**
 * 维修记录
 * */
export function maintenanceRecord(carId) {
    return request({
        url: '/admin/car/maintenance/search',
        method: 'post',
        data: {
            carId,
        }
    })
}


/**
 * 维修记录
 * */
export function addMaintenanceRecord(carId, vdesc) {
    return request({
        url: '/admin/car/maintenance/add',
        method: 'post',
        data: {
            carId,
            vdesc
        }
    })
}







