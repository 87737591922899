import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "overflow-container"
};
import PageHeaderComponents from "@/components/page-header.vue";
import { reactive, ref, onMounted } from "vue";
import { addVehicle, cartype, updateVehicle, vehicleDetail } from "@/api/vehicle";
import { useRouter } from "vue-router";
import StoreAutocomplete from "@/components/store-autocomplete.vue";

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  __name: 'update',
  setup(__props) {
    const router = useRouter();
    let formData = reactive({
      "assetCode": "",
      "carNumber": "",
      "bikeNo": "",
      "istatus": "",
      "carTypeId": "",
      "storeId": "",
      "importDate": ""
    });
    const code = ref("");
    const carTypeList = ref([]);
    let id = ref(0);
    onMounted(() => {
      id.value = history.state.id;
      cartype().then(res => {
        carTypeList.value = res.data;
        if (id.value !== 0) {
          vehicleDetail(id.value).then(res => {
            formData.valueOf().assetCode = res.data.assetCode;
            formData.valueOf().carNumber = res.data.carNumber;
            formData.valueOf().istatus = res.data.istatus;
            formData.valueOf().bikeNo = res.data.bikeNo;
            formData.valueOf().carTypeId = res.data.carTypeId;
            formData.valueOf().storeName = res.data.storeName;
            formData.valueOf().storeId = res.data.storeId;
            formData.valueOf().importDate = res.data.importDate;
            code.value = res.data.scanImageUrl;
          });
        }
      });
    });
    const add = () => {
      addVehicle(formData.valueOf()).then(res => {
        if (res.result === 0) {
          ElMessage({
            message: res.msg,
            type: 'success'
          });
          setTimeout(() => {
            router.go(-1);
          }, 1500);
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const update = () => {
      console.log(formData.valueOf().storeId);
      updateVehicle({
        id: id.value,
        istatus: formData.valueOf().istatus,
        storeId: formData.valueOf().storeId
      }).then(res => {
        if (res.result === 0) {
          ElMessage({
            message: res.msg,
            type: 'success'
          });
          setTimeout(() => {
            router.go(-1);
          }, 1500);
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const getStoreId = id => {
      formData.valueOf().storeId = id;
    };
    const importDateTime = ref("");
    const submit = () => {
      if (importDateTime.value !== "") {
        formData.valueOf().importDate = importDateTime.value.getFullYear() + "-" + (importDateTime.value.getMonth() + 1) + "-" + importDateTime.value.getDate();
      }
      if (id.value === 0) {
        add();
      } else {
        update();
      }
    };
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_date_picker = _resolveComponent("el-date-picker");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(PageHeaderComponents, {
        label: _unref(id) !== 0 ? '修改车辆' : '添加车辆'
      }, null, 8, ["label"]), _createVNode(_component_el_form, {
        ref: "ruleFormRef",
        model: _unref(formData).data,
        "label-position": "top",
        "label-width": "auto"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "车型",
          prop: "carTypeId",
          type: "number"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: _unref(formData).carTypeId,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(formData).carTypeId = $event),
            placeholder: "车型",
            disabled: _unref(id) !== 0
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(carTypeList.value, item => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item.vname,
                value: item.id
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue", "disabled"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "车架号",
          prop: "assetCode"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: _unref(id) !== 0,
            modelValue: _unref(formData).assetCode,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(formData).assetCode = $event)
          }, null, 8, ["disabled", "modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "车牌号",
          prop: "carNumber"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: _unref(id) !== 0,
            modelValue: _unref(formData).carNumber,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(formData).carNumber = $event)
          }, null, 8, ["disabled", "modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "车辆编号",
          prop: "bikeNo"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: _unref(id) !== 0,
            modelValue: _unref(formData).bikeNo,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _unref(formData).bikeNo = $event)
          }, null, 8, ["disabled", "modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "门店ID",
          prop: "storeId"
        }, {
          default: _withCtx(() => [_createVNode(StoreAutocomplete, {
            value: _unref(formData).storeName,
            styleWidth: "100%",
            onAutocompleteSelectData: getStoreId
          }, null, 8, ["value"])]),
          _: 1
        }), false ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 0,
          label: "车辆二维码",
          prop: "storeId"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_image, {
            style: {
              "width": "80px"
            },
            src: code.value
          }, null, 8, ["src"])]),
          _: 1
        })) : _createCommentVNode("", true), _unref(id) !== 0 ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 1,
          label: "入库时间",
          prop: "storeId"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: _unref(formData).importDate,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _unref(formData).importDate = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        })) : _createCommentVNode("", true), _unref(id) === 0 ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 2,
          label: "入库时间",
          prop: "bikeNo"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_date_picker, {
            disabled: _unref(id) !== 0,
            style: {
              "width": "100%"
            },
            modelValue: importDateTime.value,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => importDateTime.value = $event),
            type: "date",
            placeholder: "请选择入库时间"
          }, null, 8, ["disabled", "modelValue"])]),
          _: 1
        })) : _createCommentVNode("", true), _createVNode(_component_el_form_item, {
          label: "状态",
          prop: "istatus"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: _unref(formData).istatus,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _unref(formData).istatus = $event),
            placeholder: "状态"
          }, {
            default: _withCtx(() => [(_openBlock(), _createBlock(_component_el_option, {
              key: 0,
              label: "可租用",
              value: 0
            })), (_openBlock(), _createBlock(_component_el_option, {
              key: 1,
              label: "租用中",
              value: 1
            })), (_openBlock(), _createBlock(_component_el_option, {
              key: 2,
              label: "下架",
              value: 2
            }))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            onClick: submit,
            plain: "",
            type: "primary"
          }, {
            default: _withCtx(() => [_createTextVNode("提交")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"])]);
    };
  }
};